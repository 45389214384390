<template>
  <div class="home" style="height:100%">
    <el-container style="height:100%; background-color: #E9EEF3;">
      <el-header>
        <header-layout></header-layout>
      </el-header>
      <div style="background-color: #E9EEF3; width: 79%; margin: 0 auto; padding-top: 1.8rem; height: 20px;">
        <el-breadcrumb separator-class="el-icon-arrow-right" style="float: left; display: inline; height: 100%;">
          <el-breadcrumb-item :to="{ path: '/rank/indexbrowser' }">指标浏览器</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/rank/company', query: { code: this.$route.query.code } }">公司详情
          </el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 25px; line-height: 0.8rem;">文本详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <el-main v-loading.fullscreen.lock="loading" element-loading-text="数据加载中">
        <div style=" margin: 0 auto; width: 80%">
          <el-card class="info-card" style="display: inline-block; width: 100%; margin-bottom: 1rem">
            <el-descriptions :title="title" class="text-info">
              <el-descriptions-item label="对应公司">
                <el-link @click="goCompany">{{ company_name }}</el-link>
              </el-descriptions-item>
              <el-descriptions-item label="发布时间">{{ date_release }}</el-descriptions-item>
              <el-descriptions-item label="对应行业">
                <i :class="`el-icon--right ${icon_class[Object.values(industryOptions).indexOf(industry)]}`"></i>
                {{ industry }}
              </el-descriptions-item>
              <el-descriptions-item label="发布机构">{{ issuer }}&nbsp;</el-descriptions-item>
              <el-descriptions-item label="出处">
                <el-link :href="source" target="_blank">{{ source }}</el-link>
              </el-descriptions-item>
            </el-descriptions>
          </el-card>
          <!--          <div v-if="this.hasCloud" style="display: inline-block;height:190px;width:30%; margin: 0 auto">-->
          <!--            <div ref="wordcloud" class="wordcloud"-->
          <!--                 style="height:100%;width:100%; text-align: center"></div>-->
          <!--          </div>-->
          <!--          <div v-else style="display: inline-block;height:190px;">-->
          <!--            <img height=190px src="https://citi-1308543285.cos.ap-shanghai.myqcloud.com/nowordcloud.png">-->
          <!--          </div>-->
          <div v-if="type === 'qa'" class="abstract">
            <h2 v-if="type === 'qa'">内容</h2>
            <p>{{ abstract }}</p>
          </div>
          <!--            <div class="abstract">-->
          <!--              <h2 v-if="type === 'qa'"></h2>-->
          <!--              <h2 v-else-if="type === 'news'">源新闻</h2>-->
          <!--              <h2 v-else>源报告</h2>-->
          <!--            </div>-->

          <iframe
            v-if="type !== 'qa'"
            :src="webSource"
            onload="this.style.height= (window.innerHeight * 0.5) +'px';"
            style="width: 100%; border: hidden"
            title="Document of TextualESG"
          ></iframe>
        </div>

      </el-main>
    </el-container>
    <FooterLayoutSlim></FooterLayoutSlim>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import "echarts-wordcloud/dist/echarts-wordcloud"
import "echarts-wordcloud/dist/echarts-wordcloud.min"
import HeaderLayout from '../../components/HeaderLayout.vue'
import FooterLayout from '../../components/FooterLayout'
import {getTextDetail} from '@/api/esgtext'
import FooterLayoutSlim from '../../components/FooterLayoutSlim'

export default {
  name: 'esgtext',
  components: {
    FooterLayoutSlim,
    HeaderLayout,
    FooterLayout
  },
  data() {
    return {
      loading: false,
      text_id: '1',
      type: '',
      company_name: 'xx公司',
      date_release: '',
      industry: '',
      source: '',
      webSource: '',
      abstract: '',
      title: '',
      issuer: '中金公司',
      hasCloud: false,
      industryOptions: {
        agriculture: '农林牧渔',
        chemicals: '基础化工',
        steel: '钢铁',
        nonferrous_metal: '有色金属',
        electronics: '电子',
        cars: '汽车',
        household_ele: '家用电器',
        foods: '食品饮料',
        clothes: '纺织服饰',
        light_m: '轻工制造',
        medicine: '医药生物',
        utilities: '公用事业',
        transportation: '交通运输',
        real_estate: '房地产',
        commercial_retail: '商贸零售',
        social_service: '社会服务',
        banking: '银行',
        nobank_finance: '非银金融',
        comprehensive: '综合',
        building_mat: '建筑材料',
        building_dec: '建筑装饰',
        power: '电力设备',
        machanical: '机械设备',
        defense: '国防军工',
        cs: '计算机',
        media: '传媒',
        communication: '通信',
        coal: '煤炭',
        petroleum: '石油石化',
        e_protection: '环保',
        beauty_care: '美容护理'
      },
      wordList: [],
      icon_class: ['el-icon-grape', 'el-icon-magic-stick', 'el-icon-attract', 'el-icon-medal', 'el-icon-cpu', 'el-icon-truck',
        'el-icon-refrigerator', 'el-icon-ice-drink', 'el-icon-watch-1', 'el-icon-toilet-paper', 'el-icon-first-aid-kit',
        'el-icon-help', 'el-icon-box', 'el-icon-office-building', 'el-icon-goods', 'el-icon-service', 'el-icon-bank-card',
        'el-icon-coin', 'el-icon-connection', 'el-icon-brush', 'el-icon-scissors', 'el-icon-s-opportunity', 'el-icon-setting',
        'el-icon-aim', 'el-icon-monitor', 'el-icon-mic', 'el-icon-phone-outline', 'el-icon-discover', 'el-icon-odometer',
        'el-icon-sunny', 'el-icon-star-off']
    }
  },
  methods: {
    // 传入某文本关键字获取信息填充即可
    getESGTextDetail() {
      this.loading = true
      console.log(this.text_id)
      getTextDetail({text_id: this.text_id}).then(res => {
        // console.log(res.data)
        // console.log(res.data.wordData.length)
        if (res.code !== 0) {
          this.$message({
            message: '暂无该文本数据',
            type: 'warning',
            center: true
          })
          this.loading = false
          return
        }
        this.source = res.data.source
        this.webSource = this.source.replace(/^http/, "https");
        this.title = res.data.title
        this.type = res.data.type
        this.date_release = res.data.date_release
        this.abstract = res.data.abstract
        this.company_name = res.data.company_name
        this.issuer = this.$route.query.issuer
        this.industry = this.industryOptions[res.data.industry]
        this.loading = false
        if (res.data.wordData) {
          res.data.wordData.forEach(el => {

            this.wordList.push({name: el.name, value: el.value})
            if (this.wordList.length >= 20) return false
            // console.log(JSON.stringify(el))
          })

          this.hasCloud = true
          // this.initchart()
        } else this.hasCloud = false

        console.log(this.wordList)
        this.$message({
          message: '成功加载文本详情',
          type: 'success',
          center: true
        })
      })
    },
    // 页面左上角返回方法
    goBack() {
      this.$router.push({
        path: '/rank/company',
        query: {
          code: this.$route.query.code,
          currentPage: this.$route.query.currentPage,
          pageSize: this.$route.query.pageSize
        }
      })
    },
    // 公司名称返回公司页面，和左上角返回区别于公司页面ESG文本分页
    goCompany() {
      this.$router.push({
        path: '/rank/company',
        query: {code: this.$route.query.code}
      })
    },

    // 初始化词云图
    initchart() {

      let myChart = echarts.init(this.$refs.wordcloud);
      myChart.setOption({
        series: [
          {
            type: "wordCloud",
            //用来调整词之间的距离
            gridSize: 10,

            //用来调整字的大小范围
            sizeRange: [14, 25],

            //用来调整词的旋转方向，，[0,0]--代表着没有角度，也就是词为水平方向，需要设置角度参考注释内容
            rotationRange: [0, 0],

            //随机生成字体颜色
            // maskImage: maskImage,
            textStyle: {

              color: function () {
                return (
                  "rgb(" +
                  Math.round(Math.random() * 210) +
                  ", " +
                  Math.round(Math.random() * 210) +
                  ", " +
                  Math.round(Math.random() * 210) +
                  ")"
                );
              },
              emphasis: {
                shadowBlur: 10,
                shadowColor: '#333'
              }
            },
            backgroundColor: 'rgba(105,105,105,.0)',
            //位置相关设置
            left: "center",
            top: "center",
            right: null,
            bottom: null,
            width: "200%",
            height: "200%",
            //数据
            data: this.wordList
          }
        ]
      })
      console.log("绘制词云图")
    }
  },
  created() {
    this.loading = true
    this.text_id = this.$route.query.text_id || '1000000'
    if (localStorage.getItem('store')) {
      console.log('has token')
      this.$store.commit('replace', JSON.parse(localStorage.getItem('store')))
    }
  },
  mounted() {
    this.getESGTextDetail()


    // this.initchart();
  }
  // todo 词云
}
</script>

<style>

.el-header {
  background-color: #34495E;
  color: #333;
  text-align: center;
}

.el-page-header {
  background-color: #E9EEF3;
  height: auto;
  padding-left: 1%;
}

.el-main {
  background-color: #E9EEF3;
}

.el-descriptions-item {
  background-color: #E9EEF3;
}

.text-info {
  background-color: #E9EEF3;
  height: auto;
}

.info-card {
  background-color: #E9EEF3;
  width: 60%;
  height: auto;
  padding-top: 1%;
  padding-left: 1%;
  float: left;
}

.abstract {
  text-align: left;
  margin-top: 1rem;
}

.wordCloud {
  height: 15rem;
}
</style>
